import React, { useState, useCallback } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import {
  Smile,
  Calendar,
  ThumbsUp,
  Users,
  CheckCircle,
  AlertTriangle,
  TrendingUp,
  Heart,
  DollarSign,
  Activity,
  Repeat,
  Clock,
  Shield,
  Maximize,
  Minimize,
} from 'lucide-react';

const CompactDashboard = ({ isDarkMode }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = useCallback(async () => {
    const dashboardElement = document.getElementById('dashboard-container');
    if (!document.fullscreenElement) {
      try {
        await dashboardElement.requestFullscreen();
        setIsFullscreen(true);
      } catch (err) {
        console.log(`Error attempting to enable fullscreen: ${err.message}`);
      }
    } else {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  }, []);

 
    // Sample data for pediatric dental practice
    const monthlyData = [
      { month: 'Jan', revenue: 45000, newPatients: 25, overhead: 30000 },
      { month: 'Feb', revenue: 46000, newPatients: 27, overhead: 30500 },
      { month: 'Mar', revenue: 48000, newPatients: 30, overhead: 31000 },
      { month: 'Apr', revenue: 47000, newPatients: 28, overhead: 29500 },
      { month: 'May', revenue: 49000, newPatients: 32, overhead: 30000 },
      { month: 'Jun', revenue: 50000, newPatients: 29, overhead: 29000 },
    ];
  
    const treatmentAcceptanceData = [
      { name: 'Sealants', value: 85 },
      { name: 'Fillings', value: 78 },
      { name: 'Crowns', value: 72 },
    ];
  
    // Sample KPIs
    const kpis = [
      {
        title: 'Patient Retention Rate',
        value: '88%',
        change: '+2% from last month',
        icon: <Repeat className="h-5 w-5 text-blue-500 ml-2" />,
        colorClass: 'bg-blue-50',
        textColorClass: 'text-blue-600',
      },
      {
        title: 'New Patient Growth',
        value: '29',
        change: '+2 from last month',
        icon: <Users className="h-5 w-5 text-green-500 ml-2" />,
        colorClass: 'bg-green-50',
        textColorClass: 'text-green-600',
      },
      {
        title: 'Revenue per Patient',
        value: '$1,200',
        change: '+$50 from last month',
        icon: <DollarSign className="h-5 w-5 text-yellow-500 ml-2" />,
        colorClass: 'bg-yellow-50',
        textColorClass: 'text-yellow-600',
      },
      {
        title: 'Overhead Ratio',
        value: '60%',
        change: '-1% from last month',
        icon: <Activity className="h-5 w-5 text-purple-500 ml-2" />,
        colorClass: 'bg-purple-50',
        textColorClass: 'text-purple-600',
      },
      {
        title: 'Profit Margin',
        value: '40%',
        change: '+1.5% from last month',
        icon: <TrendingUp className="h-5 w-5 text-indigo-500 ml-2" />,
        colorClass: 'bg-indigo-50',
        textColorClass: 'text-indigo-600',
      },
      {
        title: 'Treatment Acceptance Rate',
        value: '78%',
        change: '+4% from last month',
        icon: <ThumbsUp className="h-5 w-5 text-teal-500 ml-2" />,
        colorClass: 'bg-teal-50',
        textColorClass: 'text-teal-600',
      },
      {
        title: 'Average Wait Time',
        value: '10 mins',
        change: '-2 mins from last month',
        icon: <Clock className="h-5 w-5 text-red-500 ml-2" />,
        colorClass: 'bg-red-50',
        textColorClass: 'text-red-600',
      },
      {
        title: 'Staff Turnover Rate',
        value: '5%',
        change: 'Stable',
        icon: <Shield className="h-5 w-5 text-pink-500 ml-2" />,
        colorClass: 'bg-pink-50',
        textColorClass: 'text-pink-600',
      },
    ];
  
    // Utility functions for class names
    const getBgClass = (lightModeClass) =>
      isDarkMode ? 'bg-slate-800' : lightModeClass;
  
    const getTextClass = () => (isDarkMode ? 'text-gray-300' : 'text-gray-800');
  
    // AI-generated summary based on KPIs
    const generateSummary = () => {
      // Sample logic for summary generation
      return (
        "Your practice is performing well with a steady increase in new patients and a high patient retention rate of 88%. The overhead ratio has decreased to 60%, improving your profit margin to 40%. Focus on maintaining the reduced average wait time and continue efforts to increase the treatment acceptance rate."
      );
    };

  return (
    <div
      id="dashboard-container"
      className={`${
        isDarkMode ? 'bg-slate-900 text-white' : 'bg-white text-gray-800'
      } p-4 rounded-lg relative ${
        isFullscreen ? 'fixed inset-0 z-50 overflow-y-auto' : ''
      }`}
    >
{/* Fullscreen Toggle Button */}
<button
  onClick={toggleFullscreen}
  className={`absolute top-4 right-4 p-2 rounded-lg z-10 ${
    isDarkMode ? 'bg-slate-700 hover:bg-slate-600' : 'bg-gray-100 hover:bg-gray-200'
  } transition-colors duration-200 hidden sm:block`} // Add hidden on mobile
  aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
>
  {isFullscreen ? (
    <Minimize className={`h-5 w-5 ${isDarkMode ? 'text-white' : 'text-gray-600'}`} />
  ) : (
    <Maximize className={`h-5 w-5 ${isDarkMode ? 'text-white' : 'text-gray-600'}`} />
  )}
</button>



    
      {/* AI Summary Section */}
      <div
        className={`mb-6 p-4 rounded-lg shadow-md ${
          isDarkMode ? 'bg-slate-800 text-white' : 'bg-blue-50 text-gray-800'
        }`}
      >
        <h3 className="text-lg font-semibold mb-2">Performance Summary</h3>
        <p className="text-sm">{generateSummary()}</p>
      </div>

      {/* KPI Cards */}
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mb-6">
        {kpis.map((kpi, index) => (
          <div
            key={index}
            className={`${getBgClass(kpi.colorClass)} rounded-lg shadow-md p-4`}
          >
            <div className={`${getTextClass()} text-sm font-medium`}>
              {kpi.title}
            </div>
            <div className="flex items-center mt-2">
              <span className="text-2xl font-bold">{kpi.value}</span>
              {kpi.icon}
            </div>
            <div className={`text-sm mt-1 ${kpi.textColorClass}`}>
              {kpi.change}
            </div>
          </div>
        ))}
      </div>

      {/* Charts Section */}
<div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
  {/* Revenue Over Time */}
  <div className={`rounded-lg shadow-md p-4 ${isDarkMode ? 'bg-slate-800 text-white' : 'bg-white text-gray-800'}`}>
    <h3 className="text-lg font-semibold mb-4 text-center">Revenue Over Last 6 Months</h3>
    <div className="h-38"> {/* Reduced from h-64 */}
      <ResponsiveContainer width="100%" height={150}> {/* Reduced height */}
        <LineChart data={monthlyData} margin={{ top: 10, right: 20, left: -10, bottom: -10 }}>
          <CartesianGrid strokeDasharray="3 3" stroke={isDarkMode ? '#4B5563' : '#E2E8F0'} />
          <XAxis dataKey="month" stroke={isDarkMode ? '#D1D5DB' : '#4B5563'} tick={{ fontSize: 12 }} />
          <YAxis stroke={isDarkMode ? '#D1D5DB' : '#4B5563'} tickFormatter={(value) => `$${(value / 1000).toFixed(0)}k`} tick={{ fontSize: 12 }} />
          <Tooltip contentStyle={{ backgroundColor: isDarkMode ? '#1F2937' : '#FFFFFF', borderColor: isDarkMode ? '#374151' : '#E5E7EB', color: isDarkMode ? '#FFFFFF' : '#000000' }} itemStyle={{ color: isDarkMode ? '#D1D5DB' : '#4B5563' }} formatter={(value) => `$${value.toLocaleString()}`} />
          <Line type="monotone" dataKey="revenue" stroke="#2563EB" strokeWidth={3} dot={{ r: 2 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>

  {/* Overhead Ratio Over Time */}
  <div className={`rounded-lg shadow-md p-4 ${isDarkMode ? 'bg-slate-800 text-white' : 'bg-white text-gray-800'}`}>
    <h3 className="text-lg font-semibold mb-4 text-center">Overhead Ratio Over Last 6 Months</h3>
    <div className="h-38"> {/* Reduced from h-64 */}
      <ResponsiveContainer width="100%" height={150}> {/* Reduced height */}
        <LineChart data={monthlyData} margin={{ top: 10, right: 20, left: -10, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" stroke={isDarkMode ? '#4B5563' : '#E2E8F0'} />
          <XAxis dataKey="month" stroke={isDarkMode ? '#D1D5DB' : '#4B5563'} tick={{ fontSize: 12 }} />
          <YAxis stroke={isDarkMode ? '#D1D5DB' : '#4B5563'} tickFormatter={(value) => `${value}%`} tick={{ fontSize: 12 }} />
          <Tooltip contentStyle={{ backgroundColor: isDarkMode ? '#1F2937' : '#FFFFFF', borderColor: isDarkMode ? '#374151' : '#E5E7EB', color: isDarkMode ? '#FFFFFF' : '#000000' }} itemStyle={{ color: isDarkMode ? '#D1D5DB' : '#4B5563' }} formatter={(value) => `${value}%`} />
          <Line type="monotone" dataKey={(data) => ((data.overhead / data.revenue) * 100).toFixed(2)} name="Overhead Ratio" stroke="#10B981" strokeWidth={3} dot={{ r: 2 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>
</div>

      {/* Additional Charts */}
<div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
  {/* New Patients Over Time */}
  <div className={`rounded-lg shadow-md p-4 ${isDarkMode ? 'bg-slate-800 text-white' : 'bg-white text-gray-800'}`}>
    <h3 className="text-lg font-semibold mb-4 text-center">New Patients Over Last 6 Months</h3>
    <div className="h-48"> {/* Reduced from h-64 */}
      <ResponsiveContainer width="100%" height={150}> {/* Reduced height */}
        <BarChart data={monthlyData} margin={{ top: 10, right: 20, left: -10, bottom: -10 }}>
          <CartesianGrid strokeDasharray="3 3" stroke={isDarkMode ? '#4B5563' : '#E2E8F0'} />
          <XAxis dataKey="month" stroke={isDarkMode ? '#D1D5DB' : '#4B5563'} tick={{ fontSize: 12 }} />
          <YAxis stroke={isDarkMode ? '#D1D5DB' : '#4B5563'} tick={{ fontSize: 12 }} />
          <Tooltip contentStyle={{ backgroundColor: isDarkMode ? '#1F2937' : '#FFFFFF', borderColor: isDarkMode ? '#374151' : '#E5E7EB', color: isDarkMode ? '#FFFFFF' : '#000000' }} itemStyle={{ color: isDarkMode ? '#D1D5DB' : '#4B5563' }} />
          <Bar dataKey="newPatients" fill="#8B5CF6" barSize={30} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  </div>

  {/* Treatment Acceptance Rates */}
  <div className={`rounded-lg shadow-md p-4 ${isDarkMode ? 'bg-slate-800 text-white' : 'bg-white text-gray-800'}`}>
    <h3 className="text-lg font-semibold mb-4 text-center">Treatment Acceptance Rates</h3>
    <div className="h-48"> {/* Reduced from h-64 */}
      <ResponsiveContainer width="100%" height={150}> {/* Reduced height */}
        <BarChart data={treatmentAcceptanceData} layout="vertical" margin={{ top: 10, right: 20, left: -10, bottom: -10 }}>
          <CartesianGrid strokeDasharray="3 3" stroke={isDarkMode ? '#4B5563' : '#E2E8F0'} />
          <XAxis type="number" domain={[0, 100]} stroke={isDarkMode ? '#D1D5DB' : '#4B5563'} tick={{ fontSize: 12 }} tickFormatter={(value) => `${value}%`} />
          <YAxis type="category" dataKey="name" stroke={isDarkMode ? '#D1D5DB' : '#4B5563'} tick={{ fontSize: 12 }} />
          <Tooltip formatter={(value) => `${value}%`} contentStyle={{ backgroundColor: isDarkMode ? '#1F2937' : '#FFFFFF', borderColor: isDarkMode ? '#374151' : '#E5E7EB', color: isDarkMode ? '#FFFFFF' : '#000000' }} itemStyle={{ color: isDarkMode ? '#D1D5DB' : '#4B5563' }} />
          <Bar dataKey="value" fill="#F59E0B" barSize={20} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  </div>
</div>

      {/* Action Items Section */}
      <div
        className={`${getBgClass('bg-red-50')} rounded-lg shadow-md p-4 mb-6`}
      >
        <h3 className="text-sm font-semibold mb-4">Action Items</h3>
        <div className="flex flex-wrap gap-4">
          <div className="flex items-center flex-1 w-full md:w-auto">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <span className="text-sm truncate">20 Overdue Recalls</span>
          </div>
          <div className="flex items-center flex-1 w-full md:w-auto">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <span className="text-sm truncate">12 Pending Treatments</span>
          </div>
          <div className="flex items-center flex-1 w-full md:w-auto">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <span className="text-sm truncate">7 Insurance Claims</span>
          </div>
        </div>
      </div>

      {/* Additional Cards for In-Depth Insights */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Patient Satisfaction */}
        <div
          className={`${getBgClass('bg-white')} rounded-lg shadow-md p-4`}
        >
          <h3 className="text-lg font-semibold mb-4 text-center">
            Patient Satisfaction
          </h3>
          <div className="flex items-center justify-center h-64">
            <div className="text-center">
              <Smile className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
              <p className="text-4xl font-bold">94%</p>
              <p className="text-sm text-yellow-600">Positive Feedback</p>
            </div>
          </div>
        </div>

        {/* Staff Turnover Rate */}
        <div
          className={`${getBgClass('bg-white')} rounded-lg shadow-md p-4`}>
          <h3 className="text-lg font-semibold mb-4 text-center">
            Staff Turnover Rate
          </h3>
          <div className="flex items-center justify-center h-64">
            <div className="text-center">
              <Shield className="h-16 w-16 text-pink-500 mx-auto mb-4" />
              <p className="text-4xl font-bold">5%</p>
              <p className="text-sm text-pink-600">Stable Team</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default CompactDashboard;